import { useSignupGuest } from 'src/api/auth/hooks';
import { useAuth } from 'src/utils/auth/useAuth';

export const useGuestUser = () => {
  const signupGuestMutation = useSignupGuest();
  const { login } = useAuth();

  const createGuestUser = async (displayName: string) => {
    try {
      const { signUpGuest } = await signupGuestMutation.mutateAsync({
        input: {
          displayName,
        },
      });

      return signUpGuest;
    } catch {
      return null;
    }
  };

  const loginGuestUser = async (displayName: string) => {
    const userDetails = await createGuestUser(displayName);

    const accessToken = userDetails?.accessToken;
    const user = userDetails?.user;

    if (!accessToken || !user) return null;

    login({ accessToken, user });

    return user;
  };

  return { createGuestUser, loginGuestUser };
};
