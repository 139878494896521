import React, { useState } from 'react';
import {
  Box,
  ButtonBase,
  Fade,
  IconButton,
  InputAdornment,
  Modal as MuiModal,
  Paper,
  styled,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import {
  EyeIcon,
  EyeOffIcon,
  KeyRoundIcon,
  MailIcon,
  XIcon,
} from 'lucide-react';
import { useRouter } from 'next/router';
import * as yup from 'yup';

import { gqlClient } from 'src/api/gqlClient';
import { Alert } from 'src/components/Alert/Alert';
import { Button } from 'src/components/Button/Button';
import { TextField } from 'src/components/TextField/TextField';
import { Txt } from 'src/components/Txt/Txt';
import { RoutePath } from 'src/constants/RoutePath';
import { colors } from 'src/design-system/design-system.colors';
import { User, useSignInMutation } from 'src/gql-generated';
import { useLoginDialog } from 'src/hooks/useLoginDialog';
import { useAuth } from 'src/utils/auth/useAuth';
import { useOnMount } from 'src/utils/utils';

interface Fields {
  email: string;
  password: string;
  showPassword: boolean;
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('An email is required')
    .email('A valid email is required'),
  password: yup.string().min(6).required('A password is required'),
  showPassword: yup.boolean(),
});

const Modal = styled(MuiModal)`
  & .MuiBackdrop-root {
    backdrop-filter: blur(5px);
  }
`;

export const LoginDialog = () => {
  const signInMutation = useSignInMutation(gqlClient, {});
  const router = useRouter();
  const [showError, setShowError] = useState(false);
  const auth = useAuth();
  const { loginDialog, closeLoginDialog } = useLoginDialog();

  useOnMount(() => {
    auth.logout();
  });

  const handleSubmit = async (
    values: Fields,
    actions: FormikHelpers<Fields>
  ) => {
    const { email, password } = values;

    try {
      const {
        signIn: { user, accessToken },
      } = await signInMutation.mutateAsync({
        input: { email: email.trim(), password },
      });

      processLogin({ accessToken, user });
    } catch (error) {
      setShowError(true);
      auth.logout();
    }
  };

  const processLogin = async ({
    accessToken,
    user,
  }: {
    accessToken: string;
    user: User;
  }) => {
    setShowError(false);

    auth.login({ accessToken, user });

    if (loginDialog.postLoginRoute) {
      await router.push(loginDialog.postLoginRoute);
    }

    if (loginDialog.postLoginCallback) {
      loginDialog.postLoginCallback(user);
    }

    closeLoginDialog();
  };

  const getForgotPasswordUrl = (email: string) => {
    if (!email) return RoutePath.ForgotPassword;

    return `${RoutePath.ForgotPassword}?email=${encodeURIComponent(
      email.trim()
    )}`;
  };

  const handleForgotPasswordClick = (email?: string) => async () => {
    if (email) {
      await router.push({
        pathname: RoutePath.ForgotPassword,
        query: { email: encodeURIComponent(email) },
      });
    } else {
      await router.push(RoutePath.ForgotPassword);
    }

    closeLoginDialog();
  };

  const handleSignUpClick = async () => {
    await router.push(RoutePath.Register);
    closeLoginDialog();
  };

  const handleCloseClick = async () => {
    closeLoginDialog();
  };

  return (
    <Modal onClose={handleCloseClick} open>
      <Fade in>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight="100%"
        >
          <Box
            component={Paper}
            maxWidth={{ sm: '430px' }}
            width="100%"
            px={{ xs: 2, sm: 4 }}
            py={4}
            bgcolor={colors.dark[800]}
            position="relative"
          >
            <Box
              component={IconButton}
              onClick={handleCloseClick}
              size="small"
              position="absolute"
              top="8px"
              right="8px"
              color={colors.dark[300]}
            >
              <XIcon color="inherit" />
            </Box>
            <Txt
              component="h1"
              fontSize={28}
              textAlign="center"
              mb={0.5}
              fontWeight={700}
            >
              Log in
            </Txt>
            <Txt fontSize={14} textAlign="center" mb={4} color="primary">
              Create planning poker games instantly
            </Txt>

            <Formik<Fields>
              onSubmit={handleSubmit}
              initialValues={{
                email: '',
                password: '',
                showPassword: false,
              }}
              validationSchema={validationSchema}
            >
              {(form) => (
                <form onSubmit={form.handleSubmit}>
                  <Box
                    component={TextField}
                    mb={4}
                    label="Email"
                    name="email"
                    onChange={form.handleChange}
                    value={form.values.email}
                    error={form.touched.email && Boolean(form.errors.email)}
                    helperText={form.touched.email && form.errors.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailIcon
                            color={colors.primary[500]}
                            height={20}
                            width={20}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Box
                    component={TextField}
                    mb={1}
                    type={form.values.showPassword ? 'text' : 'password'}
                    label="Password"
                    name="password"
                    onChange={form.handleChange}
                    value={form.values.password}
                    error={
                      form.touched.password && Boolean(form.errors.password)
                    }
                    helperText={form.touched.password && form.errors.password}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <KeyRoundIcon
                            color={colors.primary[500]}
                            height={20}
                            width={20}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              form.setFieldValue(
                                'showPassword',
                                !form.values.showPassword
                              )
                            }
                            size="small"
                          >
                            {form.values.showPassword ? (
                              <EyeIcon
                                color={colors.primary[500]}
                                height={20}
                                width={20}
                              />
                            ) : (
                              <EyeOffIcon
                                color={colors.primary[500]}
                                height={20}
                                width={20}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Box mb={4} display="flex" justifyContent="flex-end">
                    <ButtonBase
                      onClick={handleForgotPasswordClick(form.values.email)}
                      disableRipple
                    >
                      <Txt
                        color="primary"
                        textAlign="center"
                        fontSize={14}
                        fontWeight={700}
                      >
                        Forgot password?
                      </Txt>
                    </ButtonBase>
                  </Box>

                  {showError && (
                    <Box
                      component={Alert}
                      mb={4}
                      severity="error"
                      title="Could not login"
                      body="Please check that you are using the correct credentials"
                    />
                  )}

                  <Box
                    component={Button}
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    mb={4}
                    loading={form.isSubmitting}
                  >
                    Log In
                  </Box>

                  <Box
                    component={Button}
                    fullWidth
                    variant="outlined"
                    color="primary"
                    size="large"
                    mb={3}
                    onClick={handleCloseClick}
                  >
                    Close
                  </Box>
                </form>
              )}
            </Formik>

            <Box display="flex" justifyContent="center">
              <Txt fontSize={14} mr={0.75}>
                Not yet registered?
              </Txt>
              <ButtonBase onClick={handleSignUpClick} disableRipple>
                <Txt
                  color="primary"
                  textAlign="center"
                  fontSize={14}
                  fontWeight={700}
                >
                  Create an account
                </Txt>
              </ButtonBase>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
