import { atom } from 'jotai';

import { GameTicket } from 'src/gql-generated';
import { JotaiAtom } from 'src/types/types';
import { CookieKey } from 'src/utils/CookieKey';
import { cookiesService } from 'src/utils/cookiesService';

const token = cookiesService.get(CookieKey.Token);
export const accessToken = atom(token as string);
export const selectedTicket = atom(null) as JotaiAtom<GameTicket | null>;

export const atoms = {
  accessToken, // todo - deprecated atom
  selectedTicket,
};
