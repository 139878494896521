import { useMutation } from '@tanstack/react-query';
import request, { gql } from 'graphql-request';

import {
  Mutation,
  MutationRequestPasswordResetArgs,
  MutationSetNewPasswordArgs,
  MutationSignInArgs,
  MutationSignUpArgs,
  MutationSignUpGuestArgs,
} from 'src/gql-generated';

import { APP_CONFIG } from '../api-config';

export const useSignin = () => {
  type Args = MutationSignInArgs;
  type Output = Pick<Mutation, 'signIn'>;

  const query = gql`
    mutation ($input: SignInInput!) {
      signIn(input: $input) {
        accessToken
        user {
          id
          email
          role
          status
          companyId
          firstName
          lastName
          displayName
          phone
          avatarUrl
          termsAcceptanceDate
          privacyPolicyAcceptanceDate
        }
      }
    }
  `;

  return useMutation((variables: Args) => request<Output>(APP_CONFIG.GRAPHQL_API_URL, query, variables));
};

export const useSignup = () => {
  type Args = MutationSignUpArgs;
  type Output = Pick<Mutation, 'signUp'>;

  const query = gql`
    mutation ($input: SignUpInput!) {
      signUp(input: $input) {
        accessToken
        user {
          id
          email
          role
          status
          companyId
          firstName
          lastName
          displayName
          phone
          avatarUrl
          termsAcceptanceDate
          privacyPolicyAcceptanceDate
        }
      }
    }
  `;

  return useMutation((variables: Args) => request<Output>(APP_CONFIG.GRAPHQL_API_URL, query, variables));
};

export const useSignupGuest = () => {
  type Args = MutationSignUpGuestArgs;
  type Output = Pick<Mutation, 'signUpGuest'>;

  const query = gql`
    mutation ($input: SignUpGuestInput!) {
      signUpGuest(input: $input) {
        accessToken
        user {
          id
          email
          role
          status
          companyId
          firstName
          lastName
          displayName
          phone
          avatarUrl
          termsAcceptanceDate
          privacyPolicyAcceptanceDate
        }
      }
    }
  `;

  return useMutation((variables: Args) => request<Output>(APP_CONFIG.GRAPHQL_API_URL, query, variables));
};

export const useRequestPasswordReset = () => {
  type Args = MutationRequestPasswordResetArgs;
  type Output = Pick<Mutation, 'requestPasswordReset'>;

  const query = gql`
    mutation ($input: RequestPasswordResetInput!) {
      requestPasswordReset(input: $input) {
        message
      }
    }
  `;

  return useMutation((variables: Args) => request<Output>(APP_CONFIG.GRAPHQL_API_URL, query, variables));
};

export const useSetNewPassword = () => {
  type Args = MutationSetNewPasswordArgs;
  type Output = Pick<Mutation, 'setNewPassword'>;

  const query = gql`
    mutation ($input: SetNewPasswordInput!) {
      setNewPassword(input: $input) {
        accessToken
        user {
          id
          email
          role
          status
          companyId
          firstName
          lastName
          displayName
          phone
          avatarUrl
          termsAcceptanceDate
          privacyPolicyAcceptanceDate
        }
      }
    }
  `;

  return useMutation((variables: Args) => request<Output>(APP_CONFIG.GRAPHQL_API_URL, query, variables));
};
