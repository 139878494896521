import { Box } from '@mui/material';

import { gqlClient } from '@app/api/gqlClient';
import {
  useDeleteGameTicketMutation,
  useRemoveCurrentTicketMutation,
} from '@app/gql-generated';
import { useDeleteTicketConfirmDialog } from '@app/hooks/useDeleteTicketConfirmDialog';

import { Button } from 'src/components/Button/Button';
import { Dialog } from 'src/components/Dialog/Dialog';

export interface DeleteTicketConfirmDialogProps {}

export const DeleteTicketConfirmDialog =
  ({}: DeleteTicketConfirmDialogProps) => {
    const { deleteTicketConfirmDialog, closeUserSetupDialog } =
      useDeleteTicketConfirmDialog();

    const removeCurrentTicketMutation =
      useRemoveCurrentTicketMutation(gqlClient);
    const deleteGameTicketMutation = useDeleteGameTicketMutation(gqlClient);

    const { isSelected, ticketId, gameId } = deleteTicketConfirmDialog;

    const handleConfirmClick = async () => {
      if (isSelected) {
        await removeCurrentTicketMutation.mutateAsync({ input: { gameId } });
      }

      await deleteGameTicketMutation.mutateAsync({
        input: {
          id: ticketId,
          gameId,
        },
      });

      closeUserSetupDialog();
    };

    const handleCloseClick = async () => closeUserSetupDialog();

    return (
      <Dialog
        onClose={handleCloseClick}
        open
        title="Delete ticket?"
        description="Are you sure you want to delete this ticket?"
      >
        <Box
          component={Button}
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleConfirmClick}
          size="large"
          mb={4}
          loading={
            removeCurrentTicketMutation.isLoading ||
            deleteGameTicketMutation.isLoading
          }
        >
          Yes, delete
        </Box>

        <Box
          component={Button}
          fullWidth
          variant="outlined"
          color="primary"
          size="large"
          onClick={handleCloseClick}
        >
          No, cancel
        </Box>
      </Dialog>
    );
  };
