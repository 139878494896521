import { GraphQLClient } from 'graphql-request';

import { CookieKey } from 'src/utils/CookieKey';
import { cookiesService } from 'src/utils/cookiesService';

import { APP_CONFIG } from './api-config';

export const getBearerAccessToken = () => {
  const token = cookiesService.get(CookieKey.Token);
  return token ? `Bearer ${token}` : '';
};

export const gqlClient = new GraphQLClient(APP_CONFIG.GRAPHQL_API_URL, {
  headers: {
    authorization: getBearerAccessToken(),
  },
});
