import React from 'react';
import { Box, InputLabel, TextField as MaterialTextField, TextFieldProps } from '@mui/material';

const PREFIX = 'TextField';

interface RequiredProps {}

interface DefaultProps {}

type Props = RequiredProps & DefaultProps & TextFieldProps;

const defaultProps: DefaultProps = {};

export const TextField = (props: Props) => {
  const { label, ...restProps } = props;

  return (
    <Box>
      <Box component={InputLabel} mb={0.5} htmlFor="component-simple" fontWeight={700}>
        {label}
      </Box>
      <MaterialTextField
        fullWidth
        variant="outlined"
        {...restProps}
        InputLabelProps={{ shrink: true, ...restProps.InputLabelProps }}
      />
    </Box>
  );
};

TextField.defaultProps = defaultProps;
