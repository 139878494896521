import React from 'react';
import { Box, BoxProps, Card, darken, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  AlertCircleIcon,
  CheckCircle2Icon,
  ChevronRight,
  InfoIcon,
  XCircleIcon,
} from 'lucide-react';
import Link from 'next/link';

import { colors } from 'src/design-system/design-system.colors';
import { theme } from 'src/design-system/design-system.theme';

import { Txt } from '../Txt/Txt';

const PREFIX = 'Alert';

const classes = {
  icon: `${PREFIX}-icon`,
};

const StyledCard = styled(Card)(() => ({
  [`& .${classes.icon}`]: {
    color: (props: AlertProps) => colorMap[props.severity],
  },
}));

type Severity = 'success' | 'warning' | 'error' | 'info';

interface AlertProps extends BoxProps {
  title?: string;
  body?: string;
  severity: Severity;
  className?: string;
  linkHref?: string;
  linkText?: string;
}

const colorMap = {
  success: colors.success,
  warning: colors.warning,
  error: colors.error,
  info: colors.info,
} as const;

export const Alert = (props: AlertProps) => {
  const { title, body, severity, linkHref, linkText, ...restProps } = props;
  const color = colorMap[severity];

  const renderIcon = () => {
    const iconMap = {
      success: (
        <CheckCircle2Icon height={22} width={22} className={classes.icon} />
      ),
      error: <XCircleIcon height={22} width={22} className={classes.icon} />,
      warning: (
        <AlertCircleIcon height={22} width={22} className={classes.icon} />
      ),
      info: <InfoIcon height={22} width={22} className={classes.icon} />,
    } as const;

    return iconMap[severity];
  };

  return (
    <Box
      p={1}
      bgcolor={color}
      borderRadius={`${theme.shape.borderRadius}px`}
      border={`2px solid ${darken(color, 0.3)}`}
      {...restProps}
    >
      <Stack p={0.5} direction="row" alignItems="start" spacing={1}>
        {renderIcon()}

        <Stack spacing={0.5} width="100%">
          {!!title && <Txt fontWeight={600}>{title}</Txt>}

          {!!body && <Txt fontSize={14}>{body}</Txt>}
        </Stack>
      </Stack>

      {!!linkHref && !!linkText && (
        <Link href={linkHref} passHref>
          <Txt
            fontSize={14}
            textAlign="right"
            fontWeight={600}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            columnGap={0.75}
            color={colors.text}
          >
            {linkText}
            <ChevronRight height={18} width={18} />
          </Txt>
        </Link>
      )}
    </Box>
  );
};
