import React from 'react';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { NoSsr } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { Theme, ThemeProvider } from '@mui/material/styles';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Head from 'next/head';
import { ToastContainer } from 'react-toastify';

import { AuthGaurd } from 'src/auth/AuthGaurd/AuthGaurd';
import { ModalsContainer } from 'src/components/ModalsContainer/ModalsContainer';
import { theme } from 'src/design-system/design-system.theme';
import { PageComponent } from 'src/types/types';
import { isSSR } from 'src/utils/isSSR';
import { useOnMount } from 'src/utils/utils';

import { createEmotionCache } from '../utils/createEmotionCache';

import 'react-toastify/dist/ReactToastify.min.css';
import '../styles.css';

// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
      retryDelay: 100,
    },
  },
});

const clientSideEmotionCache = createEmotionCache();

interface RequiredProps {
  Component: PageComponent;
  pageProps: any;
  emotionCache: EmotionCache;
}

export default function MyApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: RequiredProps) {
  const pageTitle = Component.pageTitle || 'Instant Planning Poker';

  const renderComponent = () => {
    const { layout } = Component;
    const C = Component as any;

    if (layout) return layout(<C {...pageProps} />);

    return <C {...pageProps} />;
  };

  return (
    <React.Fragment>
      <Head>
        <title>{pageTitle}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="description" content="Instant Planning Poker" />
        <meta property="og:title" content={pageTitle} key="ogtitle" />
        <meta
          property="og:description"
          content="Instant Planning Poker"
          key="ogdesc"
        />

        {/* Open Graph */}
        <meta
          property="og:url"
          content={isSSR ? '' : window.location.href}
          key="ogurl"
        />
        <meta
          property="og:image"
          content="/assets/toolbar-logo.png"
          key="ogimage"
        />
        <meta
          property="og:site_name"
          content="Planning Poker"
          key="ogsitename"
        />
        <meta property="og:title" content={pageTitle} key="ogtitle" />
        <meta
          property="og:description"
          content="Instant Planning Poker"
          key="ogdesc"
        />
      </Head>

      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ToastContainer
            autoClose={3000}
            hideProgressBar
            className="toastify"
          />
          <QueryClientProvider client={queryClient}>
            <ModalsContainer />
            {Component.withAuthGaurd && (
              <NoSsr>
                <AuthGaurd withAuthGaurd>{renderComponent()}</AuthGaurd>
              </NoSsr>
            )}
            {!Component.withAuthGaurd && renderComponent()}
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
        </ThemeProvider>
      </CacheProvider>
    </React.Fragment>
  );
}
