import { atom, useAtom } from 'jotai';

import { User } from 'src/gql-generated';

interface State {
  open: boolean;
  gameId: string;
  ticketId: string;
  isSelected: boolean;
  onDone?: (user: User) => void;
  onClose?: () => void;
}

const initialState: State = {
  open: false,
  gameId: '',
  ticketId: '',
  isSelected: false,
};

const dialogAtom = atom<State>(initialState);

export const useDeleteTicketConfirmDialog = () => {
  const [state, setState] = useAtom(dialogAtom);

  const openDeleteTicketConfirmDialog = (
    options: Pick<
      State,
      'onDone' | 'onClose' | 'ticketId' | 'isSelected' | 'gameId'
    >
  ) => {
    setState({
      open: true,
      ...options,
    });
  };

  const closeUserSetupDialog = () => {
    setState(initialState);

    if (state.onClose) {
      state.onClose();
    }
  };

  return {
    deleteTicketConfirmDialog: state,
    openDeleteTicketConfirmDialog,
    closeUserSetupDialog,
  };
};
