import { atom, useAtom } from 'jotai';

import { User } from 'src/gql-generated';

interface State {
  open: boolean;
  postLoginRoute?: string;
  postCloseRoute?: string;
  postLoginCallback?: (user: User) => void;
  postCloseCallback?: () => void;
  email?: string;
}

const initialState: State = {
  open: false,
  postLoginRoute: '',
  postCloseRoute: '',
  email: '',
};

const loginDialogAtom = atom<State>(initialState);

export const useLoginDialog = () => {
  const [loginDialog, setLoginDialog] = useAtom(loginDialogAtom);

  const openLoginDialog = (
    options?: Pick<State, 'email' | 'postLoginRoute' | 'postCloseRoute' | 'postLoginCallback' | 'postCloseCallback'>
  ) => {
    setLoginDialog({
      open: true,
      ...options,
    });
  };

  const closeLoginDialog = () => {
    setLoginDialog(initialState);

    if (loginDialog.postCloseCallback) {
      loginDialog.postCloseCallback();
    }
  };

  return { loginDialog, openLoginDialog, closeLoginDialog };
};
