import { atom, useAtom } from 'jotai';

import { JotaiAtom } from 'src/types/types';

export enum DialogId {
  AddTickets = 'AddTickets',
}

const dialogAtoms: Record<DialogId, JotaiAtom<boolean>> = {
  [DialogId.AddTickets]: atom(false),
};

export const useDialog = (dialogId: DialogId) => {
  const [dialog, setDialog] = useAtom(dialogAtoms[dialogId]);

  const openDialog = () => setDialog(true);

  const closeDialog = () => setDialog(false);

  return { open: !!dialog, openDialog, closeDialog };
};
