export const colors = {
  primary: {
    // '50': '#f9ecec', // main
    // '100': '#f0cfcf',
    // '200': '#e6afaf',
    // '300': '#db8e8e',
    // '400': '#d47676',
    // '500': '#cc5e5e',
    // '600': '#c75656',
    // '700': '#c04c4c',
    // '800': '#b94242',
    // '900': '#ad3131',

    // '50': '#faeaea',
    // '100': '#f3cbcb',
    // '200': '#eba9a9',
    // '300': '#e28787',
    // '400': '#dc6d6d',
    // '500': '#d65353',
    // '600': '#d14c4c',
    // '700': '#cc4242',
    // '800': '#c63939',
    // '900': '#bc2929',

    '50': '#fceded',
    '100': '#f7d1d1',
    '200': '#f2b2b2',
    '300': '#ed9393',
    '400': '#e97c7c',
    '500': '#e56565',
    '600': '#e25d5d',
    '700': '#de5353',
    '800': '#da4949',
    '900': '#d33737',
  },
  secondary: {
    '50': '#fff8ef',
    '100': '#feeed7',
    '200': '#fee2bd',
    '300': '#fed6a3',
    '400': '#fdce8f',
    '500': '#fdc57b',
    '600': '#fdbf73',
    '700': '#fcb868',
    '800': '#fcb05e',
    '900': '#fca34b',
  },
  dark: {
    // '50': '#e4e6ea',
    // '100': '#bbc0cb',
    // '200': '#8e96a8',
    // '300': '#616c85',
    // '400': '#3f4d6a',
    // '500': '#1d2d50',
    // '600': '#1a2849',
    // '700': '#152240',
    // '800': '#111c37',
    // '900': '#0a1127',

    '50': '#e8e8eb',
    '100': '#c5c6ce',
    '200': '#9ea0ad',
    '300': '#77798c',
    '400': '#5a5d74',
    '500': '#3d405b',
    '600': '#373a53',
    '700': '#2f3249',
    '800': '#272a40',
    '900': '#1a1c2f',
  },
  grey: {
    '50': '#e7e7e7',
    '100': '#c4c4c4',
    '200': '#9d9d9d',
    '300': '#757575',
    '400': '#585858',
    '500': '#3a3a3a',
    '600': '#343434',
    '700': '#2c2c2c',
    '800': '#252525',
    '900': '#181818',
  },
  // dark: '#312b47',
  black: '#423c57',
  light: '#dbdbdb',
  text: '#dbdbdb',
  success: '#3c9564',
  error: '#d84a27',
  info: '#2563eb',
  warning: '#d97706',
} as const;
