import React from 'react';
import {
  Box,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
  styled,
} from '@mui/material';

export interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
}

const StyledButton = styled(MuiButton)`
  position: relative;
`;

export const Button = (props: ButtonProps) => {
  const { loading, children, startIcon, endIcon, ...restProps } = props;

  return (
    <StyledButton
      startIcon={
        startIcon && (
          <Box
            component="span"
            display="flex"
            alignItems="center"
            sx={{ opacity: loading ? 0 : 1 }}
          >
            {startIcon}
          </Box>
        )
      }
      endIcon={
        endIcon && (
          <Box component="span" sx={{ opacity: loading ? 0 : 1 }}>
            {endIcon}
          </Box>
        )
      }
      {...restProps}
    >
      {loading && (
        <Box
          position="absolute"
          component={CircularProgress}
          size={18}
          thickness={6}
          color="inherit"
        />
      )}

      <Box sx={{ opacity: loading ? 0 : 1 }} component="span">
        {children}
      </Box>
    </StyledButton>
  );
};
