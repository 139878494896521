import { useRouter } from 'next/router';

export const useQueryParam = (param: string) => {
  const router = useRouter();

  const paramFromRouter = router.query[param];

  if (!paramFromRouter) return '';

  if (Array.isArray(paramFromRouter)) {
    if (paramFromRouter.length > 0) {
      return paramFromRouter[0];
    } else {
      return '';
    }
  }

  return paramFromRouter as string;
};
