import { useEffect } from 'react';

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useEffectOnce = (cb: () => void) => useEffect(cb, []);

export const useOnMount = (cb: () => void) =>
  useEffectOnce(() => {
    cb();
  });

export const isLastItem = (array: any[], index: number) => index === array.length - 1;

export const isBrowser = () => typeof window !== 'undefined' && !!window;
