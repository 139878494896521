import axios from 'axios';

import { CookieKey } from 'src/utils/CookieKey';
import { cookiesService } from 'src/utils/cookiesService';

const NODE_ENV = process.env.NODE_ENV;

interface AppConfig {
  BASE_API_URL: string;
  GRAPHQL_API_URL: string;
  NODE_ENV: string;
}

declare global {
  interface Window {
    APP_CONFIG: AppConfig;
  }
}

const appConfigDefault: AppConfig = {
  BASE_API_URL: process.env.NEXT_PUBLIC_API_URL || 'http://localhost:5566',
  GRAPHQL_API_URL:
    process.env.NEXT_PUBLIC_GRAPHQL_API_URL || 'http://localhost:5566/graphql',
  NODE_ENV,
};

export const { BASE_API_URL } = appConfigDefault;

export const APP_CONFIG: AppConfig = {
  BASE_API_URL: appConfigDefault.BASE_API_URL,
  GRAPHQL_API_URL: appConfigDefault.GRAPHQL_API_URL,
  NODE_ENV,
};

console.log(APP_CONFIG, '<-- APP_CONFIG'); // eslint-disable-line

export const getBearerAccessToken = () => {
  const token = cookiesService.get(CookieKey.Token);
  return token ? `Bearer ${token}` : '';
};

const instance = axios.create({
  baseURL: BASE_API_URL,
});

const axiosInstance = () => {
  instance.defaults.headers.common['Authorization'] = getBearerAccessToken();
  return instance;
};

export { axiosInstance };
