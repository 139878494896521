import { Box, styled } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';

import { gqlClient } from 'src/api/gqlClient';
import { Button } from 'src/components/Button/Button';
import { Dialog } from 'src/components/Dialog/Dialog';
import { TextField } from 'src/components/TextField/TextField';
import { useCreateGameTicketsMutation } from 'src/gql-generated';
import { DialogId, useDialog } from 'src/hooks/useDialog';
import { useQueryParam } from 'src/hooks/useQueryParam';

export interface AddTicketsDialogProps {}

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    height: auto;
  }
`;

interface Fields {
  tickets: string;
}

const placeholderText = `Ticket Title 1
Ticket Title 2
Ticket Title 3`;

const validationSchema = yup.object().shape({
  tickets: yup.string().required('Please enter at least one ticket.'),
});

export const AddTicketsDialog = (props: AddTicketsDialogProps) => {
  const addTicketsDialog = useDialog(DialogId.AddTickets);
  const gameId = useQueryParam('gameId');
  const createGameTicketsMutation = useCreateGameTicketsMutation(gqlClient);

  const handleSubmit = async (values: Fields) => {
    const { tickets } = values;

    const titles = tickets
      .split(/\r?\n|\r|\n/g)
      .filter((title) => !!title.trim())
      .map((title) => title.trim());

    if (!titles.length) return;

    createGameTicketsMutation.mutate({
      input: {
        gameId,
        titles,
      },
    });

    addTicketsDialog.closeDialog();
  };

  const handleCloseClick = async () => addTicketsDialog.closeDialog();

  return (
    <Dialog
      onClose={handleCloseClick}
      open
      title="Add tickets"
      description="Enter one ticket title per line."
    >
      <Formik<Fields>
        onSubmit={handleSubmit}
        initialValues={{
          tickets: '',
        }}
        validationSchema={validationSchema}
      >
        {(form) => (
          <form onSubmit={form.handleSubmit}>
            <Box
              component={StyledTextField}
              mb={4}
              name="tickets"
              placeholder={placeholderText}
              onChange={form.handleChange}
              value={form.values.tickets}
              error={form.touched.tickets && Boolean(form.errors.tickets)}
              helperText={form.touched.tickets && form.errors.tickets}
              rows={4}
              multiline
              height="auto"
            />

            <Box
              component={Button}
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              loading={form.isSubmitting}
              mb={4}
            >
              Add tickets
            </Box>

            <Box
              component={Button}
              fullWidth
              variant="outlined"
              color="primary"
              size="large"
              onClick={handleCloseClick}
            >
              Cancel
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
