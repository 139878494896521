import React from 'react';
import { Box } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';

import { Button } from 'src/components/Button/Button';
import { Dialog } from 'src/components/Dialog/Dialog';
import { TextField } from 'src/components/TextField/TextField';
import { Txt } from 'src/components/Txt/Txt';
import { User } from 'src/gql-generated';
import { DialogId, useDialog } from 'src/hooks/useDialog';
import { useGuestUser } from 'src/hooks/useGuestUser';
import { useLoginDialog } from 'src/hooks/useLoginDialog';
import { useUserSetupDialog } from 'src/hooks/useUserSetupDialog';
import { useAuth } from 'src/utils/auth/useAuth';

interface Fields {
  displayName: string;
}

const validationSchema = yup.object().shape({
  displayName: yup.string().trim().required('A display name is required'),
});

export const UserSetupDialog = () => {
  const { openLoginDialog } = useLoginDialog();
  const { loginGuestUser } = useGuestUser();
  const { userSetupDialog, closeUserSetupDialog } = useUserSetupDialog();

  const handleSubmit = async (values: Fields) => {
    const { displayName } = values;

    const user = await loginGuestUser(displayName);
    closeUserSetupDialog();
    if (!!userSetupDialog.onDone && !!user) userSetupDialog.onDone(user);
  };

  const postLoginCallback = (user: User) => {
    if (userSetupDialog.onDone) userSetupDialog.onDone(user);
  };

  const handleLoginClick = () => {
    closeUserSetupDialog();
    openLoginDialog({ postLoginCallback });
  };

  const handleSignupClick = () => {
    closeUserSetupDialog();
    // openSignupDialog({ postLoginCallback }); todo - implement
  };

  const handleCloseClick = async () => closeUserSetupDialog();

  return (
    <Dialog
      onClose={handleCloseClick}
      open
      title="Enter a Display Name"
      description="Join a game as a guest or log into your account."
    >
      <Formik<Fields>
        onSubmit={handleSubmit}
        initialValues={{
          displayName: '',
        }}
        validationSchema={validationSchema}
      >
        {(form) => (
          <form onSubmit={form.handleSubmit}>
            <Box
              component={TextField}
              mb={4}
              label="Display name"
              name="displayName"
              placeholder="Your name"
              onChange={form.handleChange}
              value={form.values.displayName}
              error={
                form.touched.displayName && Boolean(form.errors.displayName)
              }
              helperText={form.touched.displayName && form.errors.displayName}
            />

            <Box
              component={Button}
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              loading={form.isSubmitting}
            >
              Continue
            </Box>

            <Box my={2} display="flex" justifyContent="center">
              <Txt fontWeight={600}>Or</Txt>
            </Box>

            <Box
              component={Button}
              fullWidth
              variant="outlined"
              color="primary"
              size="large"
              onClick={handleLoginClick}
            >
              Log in
            </Box>

            {/* <Box my={2} display="flex" justifyContent="center">
              <Txt fontWeight={600}>Or</Txt>
            </Box>

            <Box
              component={Button}
              fullWidth
              variant="outlined"
              color="primary"
              size="large"
              onClick={handleSignupClick}
            >
              Sign up
            </Box> */}
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
