import Cookies, { Cookie, CookieSetOptions } from 'universal-cookie';

const cookies = new Cookies();

const namespace = '@instant_planning_poker';

const getKeyWithNamespace = (key: string) => `${namespace}/${key}`;

const set = (key: string, value: Cookie, obj?: CookieSetOptions) =>
  cookies.set(getKeyWithNamespace(key), value, {
    path: '/',
    ...obj,
  });

const get = (key: string) => cookies.get(getKeyWithNamespace(key));

const remove = (key: string) => cookies.remove(getKeyWithNamespace(key));

const cookiesService = {
  set,
  get,
  remove,
};

export { cookiesService };
