import React from 'react';
import { useRouter } from 'next/router';

import { RoutePath } from 'src/constants/RoutePath';
import { useAuth } from 'src/utils/auth/useAuth';
import { isSSR } from 'src/utils/isSSR';

interface RequiredProps {
  children: React.ReactNode;
  withAuthGaurd?: boolean;
}

export const AuthGaurd = (props: RequiredProps) => {
  const { withAuthGaurd } = props;
  const { isLoggedIn } = useAuth();
  const router = useRouter();

  // if (isSSR && withAuthGaurd) return null;

  // if (!isLoggedIn && withAuthGaurd) {
  //   router.push(RoutePath.GameOptions);
  //   return null;
  // }

  const { children } = props;
  return <>{children}</>;
};
