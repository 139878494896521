import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Formik } from 'formik';
import { useRouter } from 'next/router';
import * as yup from 'yup';

import { useCreateGameMember } from 'src/api/gameMembers/hooks';
import { gqlClient } from 'src/api/gqlClient';
import { Alert } from 'src/components/Alert/Alert';
import { Button } from 'src/components/Button/Button';
import { Dialog } from 'src/components/Dialog/Dialog';
import { TextField } from 'src/components/TextField/TextField';
import { Txt } from 'src/components/Txt/Txt';
import { RoutePath } from 'src/constants/RoutePath';
import { useCreateGameMemberMutation } from 'src/gql-generated';
import { DialogId, useDialog } from 'src/hooks/useDialog';
import { useJoinGameDialog } from 'src/hooks/useJoinGameDialog';
import { useAuth } from 'src/utils/auth/useAuth';

interface Fields {
  gameId: string;
  accessCode: string;
}

const validationSchema = yup.object().shape({
  gameId: yup.string().trim().required('A game ID is required'),
});

export const JoinGameDialog = () => {
  const { joinGameDialog, closeJoinGameDialog } = useJoinGameDialog();
  const { user } = useAuth();
  const router = useRouter();
  const createGameMemberMution = useCreateGameMemberMutation(gqlClient);
  const [error, setError] = useState('');

  const handleSubmit = async (values: Fields) => {
    const { gameId, accessCode } = values;

    setError('');

    if (!user) return;

    try {
      const result = await createGameMemberMution.mutateAsync({
        input: {
          gameId,
          userId: user.id,
          accessCode,
        },
      });

      closeJoinGameDialog();
      router.push(`${RoutePath.Game}/${gameId}`);
    } catch (error) {
      const errorBody = (error as any)?.response?.errors?.[0];

      const errorMessage =
        errorBody.message === 'Forbidden Exception'
          ? 'An access code is required for this game.'
          : 'An error occurred. Please try again.';

      setError(errorMessage);
    }
  };

  const handleCloseClick = async () => closeJoinGameDialog();

  if (!joinGameDialog.open) return null;

  return (
    <Dialog
      onClose={handleCloseClick}
      open
      title="Join Game"
      description="Enter the ID of an existing game"
    >
      <Formik<Fields>
        onSubmit={handleSubmit}
        initialValues={{
          gameId: joinGameDialog.game?.gameId || '',
          accessCode: joinGameDialog.game?.accessCode || '',
        }}
        validationSchema={validationSchema}
      >
        {(form) => (
          <form onSubmit={form.handleSubmit}>
            <Box
              component={TextField}
              mb={4}
              label="Game ID"
              name="gameId"
              placeholder="Enter game ID"
              onChange={form.handleChange}
              value={form.values.gameId}
              error={form.touched.gameId && Boolean(form.errors.gameId)}
              helperText={form.touched.gameId && form.errors.gameId}
            />

            <Box
              component={TextField}
              mb={4}
              label="Game Access Code (if required)"
              name="accessCode"
              placeholder="Enter access code"
              onChange={form.handleChange}
              value={form.values.accessCode}
              error={form.touched.accessCode && Boolean(form.errors.accessCode)}
              helperText={form.touched.accessCode && form.errors.accessCode}
            />

            {!!error && (
              <Alert severity="error" className="mb-2xl" title={error} mb={4} />
            )}

            <Box
              component={Button}
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              mb={4}
              loading={form.isSubmitting}
            >
              Join Game
            </Box>

            <Box
              component={Button}
              fullWidth
              variant="outlined"
              color="primary"
              size="large"
              mb={3}
              onClick={handleCloseClick}
            >
              Close
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
