import { atom, useAtom } from 'jotai';

import { User } from 'src/gql-generated';

interface State {
  game?: {
    gameId: string;
    accessCode?: string;
  };
  open: boolean;
  onDone?: (user: User) => void;
  onClose?: () => void;
}

const initialState: State = {
  open: false,
};

const joinGameAtom = atom<State>(initialState);

export const useJoinGameDialog = () => {
  const [joinGameDialog, setJoinGameDialog] = useAtom(joinGameAtom);

  const openJoinGameDialog = (options?: Pick<State, 'onDone' | 'onClose' | 'game'>) => {
    setJoinGameDialog({
      open: true,
      ...options,
    });
  };

  const closeJoinGameDialog = () => {
    setJoinGameDialog(initialState);

    if (joinGameDialog.onClose) {
      joinGameDialog.onClose();
    }
  };

  return { joinGameDialog, openJoinGameDialog, closeJoinGameDialog };
};
