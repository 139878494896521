import { atom, useAtom } from 'jotai';

import { User } from 'src/gql-generated';

interface State {
  open: boolean;
  onDone?: (user: User) => void;
  onClose?: () => void;
}

const initialState: State = {
  open: false,
};

const userSetupAtom = atom<State>(initialState);

export const useUserSetupDialog = () => {
  const [userSetupDialog, setUserSetupDialog] = useAtom(userSetupAtom);

  const openUserSetupDialog = (options?: Pick<State, 'onDone' | 'onClose'>) => {
    setUserSetupDialog({
      open: true,
      ...options,
    });
  };

  const closeUserSetupDialog = () => {
    setUserSetupDialog(initialState);

    if (userSetupDialog.onClose) {
      userSetupDialog.onClose();
    }
  };

  return { userSetupDialog, openUserSetupDialog, closeUserSetupDialog };
};
