import React, { ReactNode } from 'react';
import {
  Box,
  Fade,
  IconButton,
  Modal as MuiModal,
  Paper,
  styled,
} from '@mui/material';
import { XIcon } from 'lucide-react';

import { colors } from 'src/design-system/design-system.colors';

import { Txt } from '../Txt/Txt';

const Modal = styled(MuiModal)`
  & .MuiBackdrop-root {
    backdrop-filter: blur(5px);
  }
`;

export interface DialogProps {
  title?: string;
  description?: string;
  open: boolean;
  children: ReactNode;
  onClose: () => void;
}

export const Dialog = ({
  children,
  open,
  onClose,
  title,
  description,
}: DialogProps) => {
  return (
    <Modal onClose={onClose} open={open}>
      <Fade in>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight="100%"
        >
          <Box
            component={Paper}
            maxWidth={{ sm: '430px' }}
            width="100%"
            px={{ xs: 2, sm: 4 }}
            pt={5}
            pb={4}
            bgcolor={colors.dark[800]}
            position="relative"
          >
            <Box
              component={IconButton}
              onClick={onClose}
              size="small"
              position="absolute"
              top="8px"
              right="8px"
            >
              <XIcon color={colors.dark[300]} />
            </Box>

            {title && (
              <Txt
                component="h1"
                fontSize={28}
                textAlign="center"
                mb={0.5}
                fontWeight={700}
              >
                {title}
              </Txt>
            )}

            {description && (
              <Txt fontSize={16} textAlign="center" mb={4} color="primary">
                {description}
              </Txt>
            )}

            {children}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
