import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type ClearVotesInput = {
  gameId: Scalars['String']['input'];
};

export type CreateGameInput = {
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  showCardsSetting: ShowCardsSetting;
  userId: Scalars['String']['input'];
  votingSystem: Scalars['String']['input'];
};

export type CreateGameMemberInput = {
  accessCode?: InputMaybe<Scalars['String']['input']>;
  gameId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type CreateGameTicketInput = {
  gameId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateGameTicketsInput = {
  gameId: Scalars['String']['input'];
  titles: Array<Scalars['String']['input']>;
};

export type CreateUserInput = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyAcceptanceDate?: InputMaybe<Scalars['String']['input']>;
  role: Role;
  termsAcceptanceDate?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteGameInput = {
  id: Scalars['String']['input'];
};

export type DeleteGameMemberInput = {
  gameMemberId: Scalars['String']['input'];
};

export type DeleteGameTicketInput = {
  gameId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type DeleteOutput = {
  __typename?: 'DeleteOutput';
  affected?: Maybe<Scalars['Float']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteUserInput = {
  id: Scalars['String']['input'];
};

export type Game = {
  __typename?: 'Game';
  accessCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  currentTicket?: Maybe<GameTicket>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isPrivate: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  showCards: Scalars['Boolean']['output'];
  showCardsSetting: ShowCardsSetting;
  updatedAt: Scalars['String']['output'];
  user: UserId;
  votingSystem: Scalars['String']['output'];
};

export type GameMember = {
  __typename?: 'GameMember';
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  user: User;
  votedPoints?: Maybe<Scalars['String']['output']>;
};

export type GameTicket = {
  __typename?: 'GameTicket';
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  votedPoints?: Maybe<Scalars['String']['output']>;
};

export type GetGameInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type GetGameMemberInput = {
  gameId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type GetGameMembersInput = {
  gameId: Scalars['String']['input'];
};

export type GetGameTicketInput = {
  id: Scalars['String']['input'];
};

export type GetGameTicketsInput = {
  gameId: Scalars['String']['input'];
};

export type GetUserInput = {
  id: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  clearVotes: Array<GameMember>;
  createGame: Game;
  createGameMember: GameMember;
  createGameTicket: GameTicket;
  createGameTickets: Array<GameTicket>;
  createUser: User;
  deleteGame: DeleteOutput;
  deleteGameMember: DeleteOutput;
  deleteGameTicket: DeleteOutput;
  deleteUser: DeleteOutput;
  removeCurrentTicket: Game;
  requestPasswordReset: RequestPasswordResetOutput;
  sendEmailVerification: SendEmailVerificationOutput;
  sendVote: GameMember;
  setCurrentTicket: Game;
  setNewPassword: SignInOutput;
  signIn: SignInOutput;
  signUp: SignInOutput;
  signUpGuest: SignInOutput;
  toggleShowCards: Game;
  updateGame: Game;
  updateGameTicket: GameTicket;
  updateUser: User;
  verifyEmail: VerifyEmailOutput;
};


export type MutationClearVotesArgs = {
  input: ClearVotesInput;
};


export type MutationCreateGameArgs = {
  input: CreateGameInput;
};


export type MutationCreateGameMemberArgs = {
  input: CreateGameMemberInput;
};


export type MutationCreateGameTicketArgs = {
  input: CreateGameTicketInput;
};


export type MutationCreateGameTicketsArgs = {
  input: CreateGameTicketsInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteGameArgs = {
  input: DeleteGameInput;
};


export type MutationDeleteGameMemberArgs = {
  input: DeleteGameMemberInput;
};


export type MutationDeleteGameTicketArgs = {
  input: DeleteGameTicketInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationRemoveCurrentTicketArgs = {
  input: RemoveCurrentTicketInput;
};


export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};


export type MutationSendEmailVerificationArgs = {
  input: SendEmailVerificationInput;
};


export type MutationSendVoteArgs = {
  input: SendVoteInput;
};


export type MutationSetCurrentTicketArgs = {
  input: SetCurrentTicketInput;
};


export type MutationSetNewPasswordArgs = {
  input: SetNewPasswordInput;
};


export type MutationSignInArgs = {
  input: SignInInput;
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationSignUpGuestArgs = {
  input: SignUpGuestInput;
};


export type MutationToggleShowCardsArgs = {
  input: ToggleShowCardsInput;
};


export type MutationUpdateGameArgs = {
  input: UpdateGameInput;
};


export type MutationUpdateGameTicketArgs = {
  input: UpdateGameTicketInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};

export type Query = {
  __typename?: 'Query';
  getGame: Game;
  getGameMember: GameMember;
  getGameMembers: Array<GameMember>;
  getGameTicket: GameTicket;
  getGameTickets: Array<GameTicket>;
  getUser: User;
};


export type QueryGetGameArgs = {
  input: GetGameInput;
};


export type QueryGetGameMemberArgs = {
  input: GetGameMemberInput;
};


export type QueryGetGameMembersArgs = {
  input: GetGameMembersInput;
};


export type QueryGetGameTicketArgs = {
  input: GetGameTicketInput;
};


export type QueryGetGameTicketsArgs = {
  input: GetGameTicketsInput;
};


export type QueryGetUserArgs = {
  input: GetUserInput;
};

export type RemoveCurrentTicketInput = {
  gameId: Scalars['String']['input'];
};

export type RequestPasswordResetInput = {
  email: Scalars['String']['input'];
};

export type RequestPasswordResetOutput = {
  __typename?: 'RequestPasswordResetOutput';
  message: Scalars['String']['output'];
};

export enum Role {
  Admin = 'ADMIN',
  Guest = 'GUEST',
  Manager = 'MANAGER',
  Staff = 'STAFF',
  Superadmin = 'SUPERADMIN',
  User = 'USER'
}

export type SendEmailVerificationInput = {
  email: Scalars['String']['input'];
};

export type SendEmailVerificationOutput = {
  __typename?: 'SendEmailVerificationOutput';
  message: Scalars['String']['output'];
};

export type SendVoteInput = {
  gameMemberId: Scalars['String']['input'];
  votedPoints: Scalars['String']['input'];
};

export type SetCurrentTicketInput = {
  gameId: Scalars['String']['input'];
  gameTicketId?: InputMaybe<Scalars['String']['input']>;
};

export type SetNewPasswordInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export enum ShowCardsSetting {
  Anyone = 'ANYONE',
  OnlyGameAdmin = 'ONLY_GAME_ADMIN'
}

export type SignInInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type SignInOutput = {
  __typename?: 'SignInOutput';
  accessToken: Scalars['String']['output'];
  user: User;
};

export type SignUpGuestInput = {
  displayName: Scalars['String']['input'];
};

export type SignUpInput = {
  displayName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  role: Role;
};

export enum Status {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE'
}

export type ToggleShowCardsInput = {
  gameId: Scalars['String']['input'];
  showCards?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateGameInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  showCards?: InputMaybe<Scalars['Boolean']['input']>;
  showCardsSetting?: InputMaybe<ShowCardsSetting>;
  votingSystem?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGameTicketInput = {
  id: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  votedPoints?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressLine3?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyAcceptanceDate?: InputMaybe<Scalars['String']['input']>;
  receiveNewletter?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<Role>;
  setPasswordExpire?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Status>;
  termsAcceptanceDate?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressLine3?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerified: Scalars['Boolean']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isPrivate: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postCode?: Maybe<Scalars['String']['output']>;
  privacyPolicyAcceptanceDate?: Maybe<Scalars['String']['output']>;
  receiveNewletter: Scalars['Boolean']['output'];
  role: Role;
  status: Status;
  termsAcceptanceDate?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type UserId = {
  __typename?: 'UserId';
  id: Scalars['String']['output'];
};

export type VerifyEmailInput = {
  email: Scalars['String']['input'];
};

export type VerifyEmailOutput = {
  __typename?: 'VerifyEmailOutput';
  message: Scalars['String']['output'];
};

export type ClearVotesMutationVariables = Exact<{
  input: ClearVotesInput;
}>;


export type ClearVotesMutation = { __typename?: 'Mutation', clearVotes: Array<{ __typename?: 'GameMember', createdAt: string, id: string, updatedAt: string, votedPoints?: string | null, user: { __typename?: 'User', addressLine1?: string | null, addressLine2?: string | null, addressLine3?: string | null, avatarUrl?: string | null, city?: string | null, companyId?: string | null, countryCode?: string | null, county?: string | null, createdAt: string, deletedAt?: string | null, displayName?: string | null, email?: string | null, emailVerified: boolean, firstName?: string | null, id: string, isPrivate: boolean, lastName?: string | null, phone?: string | null, postCode?: string | null, privacyPolicyAcceptanceDate?: string | null, receiveNewletter: boolean, role: Role, status: Status, termsAcceptanceDate?: string | null, updatedAt: string } }> };

export type CreateGameMutationVariables = Exact<{
  input: CreateGameInput;
}>;


export type CreateGameMutation = { __typename?: 'Mutation', createGame: { __typename?: 'Game', accessCode?: string | null, createdAt: string, deletedAt?: string | null, description?: string | null, id: string, isPrivate: boolean, name?: string | null, showCards: boolean, showCardsSetting: ShowCardsSetting, updatedAt: string, votingSystem: string, currentTicket?: { __typename?: 'GameTicket', createdAt: string, id: string, title?: string | null, updatedAt: string, votedPoints?: string | null } | null, user: { __typename?: 'UserId', id: string } } };

export type CreateGameMemberMutationVariables = Exact<{
  input: CreateGameMemberInput;
}>;


export type CreateGameMemberMutation = { __typename?: 'Mutation', createGameMember: { __typename?: 'GameMember', createdAt: string, id: string, updatedAt: string, votedPoints?: string | null, user: { __typename?: 'User', addressLine1?: string | null, addressLine2?: string | null, addressLine3?: string | null, avatarUrl?: string | null, city?: string | null, companyId?: string | null, countryCode?: string | null, county?: string | null, createdAt: string, deletedAt?: string | null, displayName?: string | null, email?: string | null, emailVerified: boolean, firstName?: string | null, id: string, isPrivate: boolean, lastName?: string | null, phone?: string | null, postCode?: string | null, privacyPolicyAcceptanceDate?: string | null, receiveNewletter: boolean, role: Role, status: Status, termsAcceptanceDate?: string | null, updatedAt: string } } };

export type CreateGameTicketMutationVariables = Exact<{
  input: CreateGameTicketInput;
}>;


export type CreateGameTicketMutation = { __typename?: 'Mutation', createGameTicket: { __typename?: 'GameTicket', createdAt: string, id: string, title?: string | null, updatedAt: string, votedPoints?: string | null } };

export type CreateGameTicketsMutationVariables = Exact<{
  input: CreateGameTicketsInput;
}>;


export type CreateGameTicketsMutation = { __typename?: 'Mutation', createGameTickets: Array<{ __typename?: 'GameTicket', createdAt: string, id: string, title?: string | null, updatedAt: string, votedPoints?: string | null }> };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', addressLine1?: string | null, addressLine2?: string | null, addressLine3?: string | null, avatarUrl?: string | null, city?: string | null, companyId?: string | null, countryCode?: string | null, county?: string | null, createdAt: string, deletedAt?: string | null, displayName?: string | null, email?: string | null, emailVerified: boolean, firstName?: string | null, id: string, isPrivate: boolean, lastName?: string | null, phone?: string | null, postCode?: string | null, privacyPolicyAcceptanceDate?: string | null, receiveNewletter: boolean, role: Role, status: Status, termsAcceptanceDate?: string | null, updatedAt: string } };

export type DeleteGameMutationVariables = Exact<{
  input: DeleteGameInput;
}>;


export type DeleteGameMutation = { __typename?: 'Mutation', deleteGame: { __typename?: 'DeleteOutput', affected?: number | null, success?: boolean | null } };

export type DeleteGameMemberMutationVariables = Exact<{
  input: DeleteGameMemberInput;
}>;


export type DeleteGameMemberMutation = { __typename?: 'Mutation', deleteGameMember: { __typename?: 'DeleteOutput', affected?: number | null, success?: boolean | null } };

export type DeleteGameTicketMutationVariables = Exact<{
  input: DeleteGameTicketInput;
}>;


export type DeleteGameTicketMutation = { __typename?: 'Mutation', deleteGameTicket: { __typename?: 'DeleteOutput', affected?: number | null, success?: boolean | null } };

export type DeleteUserMutationVariables = Exact<{
  input: DeleteUserInput;
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'DeleteOutput', affected?: number | null, success?: boolean | null } };

export type RemoveCurrentTicketMutationVariables = Exact<{
  input: RemoveCurrentTicketInput;
}>;


export type RemoveCurrentTicketMutation = { __typename?: 'Mutation', removeCurrentTicket: { __typename?: 'Game', accessCode?: string | null, createdAt: string, deletedAt?: string | null, description?: string | null, id: string, isPrivate: boolean, name?: string | null, showCards: boolean, showCardsSetting: ShowCardsSetting, updatedAt: string, votingSystem: string, currentTicket?: { __typename?: 'GameTicket', createdAt: string, id: string, title?: string | null, updatedAt: string, votedPoints?: string | null } | null, user: { __typename?: 'UserId', id: string } } };

export type RequestPasswordResetMutationVariables = Exact<{
  input: RequestPasswordResetInput;
}>;


export type RequestPasswordResetMutation = { __typename?: 'Mutation', requestPasswordReset: { __typename?: 'RequestPasswordResetOutput', message: string } };

export type SendEmailVerificationMutationVariables = Exact<{
  input: SendEmailVerificationInput;
}>;


export type SendEmailVerificationMutation = { __typename?: 'Mutation', sendEmailVerification: { __typename?: 'SendEmailVerificationOutput', message: string } };

export type SendVoteMutationVariables = Exact<{
  input: SendVoteInput;
}>;


export type SendVoteMutation = { __typename?: 'Mutation', sendVote: { __typename?: 'GameMember', createdAt: string, id: string, updatedAt: string, votedPoints?: string | null, user: { __typename?: 'User', addressLine1?: string | null, addressLine2?: string | null, addressLine3?: string | null, avatarUrl?: string | null, city?: string | null, companyId?: string | null, countryCode?: string | null, county?: string | null, createdAt: string, deletedAt?: string | null, displayName?: string | null, email?: string | null, emailVerified: boolean, firstName?: string | null, id: string, isPrivate: boolean, lastName?: string | null, phone?: string | null, postCode?: string | null, privacyPolicyAcceptanceDate?: string | null, receiveNewletter: boolean, role: Role, status: Status, termsAcceptanceDate?: string | null, updatedAt: string } } };

export type SetCurrentTicketMutationVariables = Exact<{
  input: SetCurrentTicketInput;
}>;


export type SetCurrentTicketMutation = { __typename?: 'Mutation', setCurrentTicket: { __typename?: 'Game', accessCode?: string | null, createdAt: string, deletedAt?: string | null, description?: string | null, id: string, isPrivate: boolean, name?: string | null, showCards: boolean, showCardsSetting: ShowCardsSetting, updatedAt: string, votingSystem: string, currentTicket?: { __typename?: 'GameTicket', createdAt: string, id: string, title?: string | null, updatedAt: string, votedPoints?: string | null } | null, user: { __typename?: 'UserId', id: string } } };

export type SetNewPasswordMutationVariables = Exact<{
  input: SetNewPasswordInput;
}>;


export type SetNewPasswordMutation = { __typename?: 'Mutation', setNewPassword: { __typename?: 'SignInOutput', accessToken: string, user: { __typename?: 'User', addressLine1?: string | null, addressLine2?: string | null, addressLine3?: string | null, avatarUrl?: string | null, city?: string | null, companyId?: string | null, countryCode?: string | null, county?: string | null, createdAt: string, deletedAt?: string | null, displayName?: string | null, email?: string | null, emailVerified: boolean, firstName?: string | null, id: string, isPrivate: boolean, lastName?: string | null, phone?: string | null, postCode?: string | null, privacyPolicyAcceptanceDate?: string | null, receiveNewletter: boolean, role: Role, status: Status, termsAcceptanceDate?: string | null, updatedAt: string } } };

export type SignInMutationVariables = Exact<{
  input: SignInInput;
}>;


export type SignInMutation = { __typename?: 'Mutation', signIn: { __typename?: 'SignInOutput', accessToken: string, user: { __typename?: 'User', addressLine1?: string | null, addressLine2?: string | null, addressLine3?: string | null, avatarUrl?: string | null, city?: string | null, companyId?: string | null, countryCode?: string | null, county?: string | null, createdAt: string, deletedAt?: string | null, displayName?: string | null, email?: string | null, emailVerified: boolean, firstName?: string | null, id: string, isPrivate: boolean, lastName?: string | null, phone?: string | null, postCode?: string | null, privacyPolicyAcceptanceDate?: string | null, receiveNewletter: boolean, role: Role, status: Status, termsAcceptanceDate?: string | null, updatedAt: string } } };

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUp: { __typename?: 'SignInOutput', accessToken: string, user: { __typename?: 'User', addressLine1?: string | null, addressLine2?: string | null, addressLine3?: string | null, avatarUrl?: string | null, city?: string | null, companyId?: string | null, countryCode?: string | null, county?: string | null, createdAt: string, deletedAt?: string | null, displayName?: string | null, email?: string | null, emailVerified: boolean, firstName?: string | null, id: string, isPrivate: boolean, lastName?: string | null, phone?: string | null, postCode?: string | null, privacyPolicyAcceptanceDate?: string | null, receiveNewletter: boolean, role: Role, status: Status, termsAcceptanceDate?: string | null, updatedAt: string } } };

export type SignUpGuestMutationVariables = Exact<{
  input: SignUpGuestInput;
}>;


export type SignUpGuestMutation = { __typename?: 'Mutation', signUpGuest: { __typename?: 'SignInOutput', accessToken: string, user: { __typename?: 'User', addressLine1?: string | null, addressLine2?: string | null, addressLine3?: string | null, avatarUrl?: string | null, city?: string | null, companyId?: string | null, countryCode?: string | null, county?: string | null, createdAt: string, deletedAt?: string | null, displayName?: string | null, email?: string | null, emailVerified: boolean, firstName?: string | null, id: string, isPrivate: boolean, lastName?: string | null, phone?: string | null, postCode?: string | null, privacyPolicyAcceptanceDate?: string | null, receiveNewletter: boolean, role: Role, status: Status, termsAcceptanceDate?: string | null, updatedAt: string } } };

export type ToggleShowCardsMutationVariables = Exact<{
  input: ToggleShowCardsInput;
}>;


export type ToggleShowCardsMutation = { __typename?: 'Mutation', toggleShowCards: { __typename?: 'Game', accessCode?: string | null, createdAt: string, deletedAt?: string | null, description?: string | null, id: string, isPrivate: boolean, name?: string | null, showCards: boolean, showCardsSetting: ShowCardsSetting, updatedAt: string, votingSystem: string, currentTicket?: { __typename?: 'GameTicket', createdAt: string, id: string, title?: string | null, updatedAt: string, votedPoints?: string | null } | null, user: { __typename?: 'UserId', id: string } } };

export type UpdateGameMutationVariables = Exact<{
  input: UpdateGameInput;
}>;


export type UpdateGameMutation = { __typename?: 'Mutation', updateGame: { __typename?: 'Game', accessCode?: string | null, createdAt: string, deletedAt?: string | null, description?: string | null, id: string, isPrivate: boolean, name?: string | null, showCards: boolean, showCardsSetting: ShowCardsSetting, updatedAt: string, votingSystem: string, currentTicket?: { __typename?: 'GameTicket', createdAt: string, id: string, title?: string | null, updatedAt: string, votedPoints?: string | null } | null, user: { __typename?: 'UserId', id: string } } };

export type UpdateGameTicketMutationVariables = Exact<{
  input: UpdateGameTicketInput;
}>;


export type UpdateGameTicketMutation = { __typename?: 'Mutation', updateGameTicket: { __typename?: 'GameTicket', createdAt: string, id: string, title?: string | null, updatedAt: string, votedPoints?: string | null } };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', addressLine1?: string | null, addressLine2?: string | null, addressLine3?: string | null, avatarUrl?: string | null, city?: string | null, companyId?: string | null, countryCode?: string | null, county?: string | null, createdAt: string, deletedAt?: string | null, displayName?: string | null, email?: string | null, emailVerified: boolean, firstName?: string | null, id: string, isPrivate: boolean, lastName?: string | null, phone?: string | null, postCode?: string | null, privacyPolicyAcceptanceDate?: string | null, receiveNewletter: boolean, role: Role, status: Status, termsAcceptanceDate?: string | null, updatedAt: string } };

export type VerifyEmailMutationVariables = Exact<{
  input: VerifyEmailInput;
}>;


export type VerifyEmailMutation = { __typename?: 'Mutation', verifyEmail: { __typename?: 'VerifyEmailOutput', message: string } };

export type GetGameQueryVariables = Exact<{
  input: GetGameInput;
}>;


export type GetGameQuery = { __typename?: 'Query', getGame: { __typename?: 'Game', accessCode?: string | null, createdAt: string, deletedAt?: string | null, description?: string | null, id: string, isPrivate: boolean, name?: string | null, showCards: boolean, showCardsSetting: ShowCardsSetting, updatedAt: string, votingSystem: string, currentTicket?: { __typename?: 'GameTicket', createdAt: string, id: string, title?: string | null, updatedAt: string, votedPoints?: string | null } | null, user: { __typename?: 'UserId', id: string } } };

export type GetGameMemberQueryVariables = Exact<{
  input: GetGameMemberInput;
}>;


export type GetGameMemberQuery = { __typename?: 'Query', getGameMember: { __typename?: 'GameMember', createdAt: string, id: string, updatedAt: string, votedPoints?: string | null, user: { __typename?: 'User', addressLine1?: string | null, addressLine2?: string | null, addressLine3?: string | null, avatarUrl?: string | null, city?: string | null, companyId?: string | null, countryCode?: string | null, county?: string | null, createdAt: string, deletedAt?: string | null, displayName?: string | null, email?: string | null, emailVerified: boolean, firstName?: string | null, id: string, isPrivate: boolean, lastName?: string | null, phone?: string | null, postCode?: string | null, privacyPolicyAcceptanceDate?: string | null, receiveNewletter: boolean, role: Role, status: Status, termsAcceptanceDate?: string | null, updatedAt: string } } };

export type GetGameMembersQueryVariables = Exact<{
  input: GetGameMembersInput;
}>;


export type GetGameMembersQuery = { __typename?: 'Query', getGameMembers: Array<{ __typename?: 'GameMember', createdAt: string, id: string, updatedAt: string, votedPoints?: string | null, user: { __typename?: 'User', addressLine1?: string | null, addressLine2?: string | null, addressLine3?: string | null, avatarUrl?: string | null, city?: string | null, companyId?: string | null, countryCode?: string | null, county?: string | null, createdAt: string, deletedAt?: string | null, displayName?: string | null, email?: string | null, emailVerified: boolean, firstName?: string | null, id: string, isPrivate: boolean, lastName?: string | null, phone?: string | null, postCode?: string | null, privacyPolicyAcceptanceDate?: string | null, receiveNewletter: boolean, role: Role, status: Status, termsAcceptanceDate?: string | null, updatedAt: string } }> };

export type GetGameTicketQueryVariables = Exact<{
  input: GetGameTicketInput;
}>;


export type GetGameTicketQuery = { __typename?: 'Query', getGameTicket: { __typename?: 'GameTicket', createdAt: string, id: string, title?: string | null, updatedAt: string, votedPoints?: string | null } };

export type GetGameTicketsQueryVariables = Exact<{
  input: GetGameTicketsInput;
}>;


export type GetGameTicketsQuery = { __typename?: 'Query', getGameTickets: Array<{ __typename?: 'GameTicket', createdAt: string, id: string, title?: string | null, updatedAt: string, votedPoints?: string | null }> };

export type GetUserQueryVariables = Exact<{
  input: GetUserInput;
}>;


export type GetUserQuery = { __typename?: 'Query', getUser: { __typename?: 'User', addressLine1?: string | null, addressLine2?: string | null, addressLine3?: string | null, avatarUrl?: string | null, city?: string | null, companyId?: string | null, countryCode?: string | null, county?: string | null, createdAt: string, deletedAt?: string | null, displayName?: string | null, email?: string | null, emailVerified: boolean, firstName?: string | null, id: string, isPrivate: boolean, lastName?: string | null, phone?: string | null, postCode?: string | null, privacyPolicyAcceptanceDate?: string | null, receiveNewletter: boolean, role: Role, status: Status, termsAcceptanceDate?: string | null, updatedAt: string } };


export const ClearVotesDocument = `
    mutation clearVotes($input: ClearVotesInput!) {
  clearVotes(input: $input) {
    createdAt
    id
    updatedAt
    user {
      addressLine1
      addressLine2
      addressLine3
      avatarUrl
      city
      companyId
      countryCode
      county
      createdAt
      deletedAt
      displayName
      email
      emailVerified
      firstName
      id
      isPrivate
      lastName
      phone
      postCode
      privacyPolicyAcceptanceDate
      receiveNewletter
      role
      status
      termsAcceptanceDate
      updatedAt
    }
    votedPoints
  }
}
    `;
export const useClearVotesMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ClearVotesMutation, TError, ClearVotesMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ClearVotesMutation, TError, ClearVotesMutationVariables, TContext>(
      ['clearVotes'],
      (variables?: ClearVotesMutationVariables) => fetcher<ClearVotesMutation, ClearVotesMutationVariables>(client, ClearVotesDocument, variables, headers)(),
      options
    );
export const CreateGameDocument = `
    mutation createGame($input: CreateGameInput!) {
  createGame(input: $input) {
    accessCode
    createdAt
    currentTicket {
      createdAt
      id
      title
      updatedAt
      votedPoints
    }
    deletedAt
    description
    id
    isPrivate
    name
    showCards
    showCardsSetting
    updatedAt
    user {
      id
    }
    votingSystem
  }
}
    `;
export const useCreateGameMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateGameMutation, TError, CreateGameMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateGameMutation, TError, CreateGameMutationVariables, TContext>(
      ['createGame'],
      (variables?: CreateGameMutationVariables) => fetcher<CreateGameMutation, CreateGameMutationVariables>(client, CreateGameDocument, variables, headers)(),
      options
    );
export const CreateGameMemberDocument = `
    mutation createGameMember($input: CreateGameMemberInput!) {
  createGameMember(input: $input) {
    createdAt
    id
    updatedAt
    user {
      addressLine1
      addressLine2
      addressLine3
      avatarUrl
      city
      companyId
      countryCode
      county
      createdAt
      deletedAt
      displayName
      email
      emailVerified
      firstName
      id
      isPrivate
      lastName
      phone
      postCode
      privacyPolicyAcceptanceDate
      receiveNewletter
      role
      status
      termsAcceptanceDate
      updatedAt
    }
    votedPoints
  }
}
    `;
export const useCreateGameMemberMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateGameMemberMutation, TError, CreateGameMemberMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateGameMemberMutation, TError, CreateGameMemberMutationVariables, TContext>(
      ['createGameMember'],
      (variables?: CreateGameMemberMutationVariables) => fetcher<CreateGameMemberMutation, CreateGameMemberMutationVariables>(client, CreateGameMemberDocument, variables, headers)(),
      options
    );
export const CreateGameTicketDocument = `
    mutation createGameTicket($input: CreateGameTicketInput!) {
  createGameTicket(input: $input) {
    createdAt
    id
    title
    updatedAt
    votedPoints
  }
}
    `;
export const useCreateGameTicketMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateGameTicketMutation, TError, CreateGameTicketMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateGameTicketMutation, TError, CreateGameTicketMutationVariables, TContext>(
      ['createGameTicket'],
      (variables?: CreateGameTicketMutationVariables) => fetcher<CreateGameTicketMutation, CreateGameTicketMutationVariables>(client, CreateGameTicketDocument, variables, headers)(),
      options
    );
export const CreateGameTicketsDocument = `
    mutation createGameTickets($input: CreateGameTicketsInput!) {
  createGameTickets(input: $input) {
    createdAt
    id
    title
    updatedAt
    votedPoints
  }
}
    `;
export const useCreateGameTicketsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateGameTicketsMutation, TError, CreateGameTicketsMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateGameTicketsMutation, TError, CreateGameTicketsMutationVariables, TContext>(
      ['createGameTickets'],
      (variables?: CreateGameTicketsMutationVariables) => fetcher<CreateGameTicketsMutation, CreateGameTicketsMutationVariables>(client, CreateGameTicketsDocument, variables, headers)(),
      options
    );
export const CreateUserDocument = `
    mutation createUser($input: CreateUserInput!) {
  createUser(input: $input) {
    addressLine1
    addressLine2
    addressLine3
    avatarUrl
    city
    companyId
    countryCode
    county
    createdAt
    deletedAt
    displayName
    email
    emailVerified
    firstName
    id
    isPrivate
    lastName
    phone
    postCode
    privacyPolicyAcceptanceDate
    receiveNewletter
    role
    status
    termsAcceptanceDate
    updatedAt
  }
}
    `;
export const useCreateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateUserMutation, TError, CreateUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateUserMutation, TError, CreateUserMutationVariables, TContext>(
      ['createUser'],
      (variables?: CreateUserMutationVariables) => fetcher<CreateUserMutation, CreateUserMutationVariables>(client, CreateUserDocument, variables, headers)(),
      options
    );
export const DeleteGameDocument = `
    mutation deleteGame($input: DeleteGameInput!) {
  deleteGame(input: $input) {
    affected
    success
  }
}
    `;
export const useDeleteGameMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteGameMutation, TError, DeleteGameMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteGameMutation, TError, DeleteGameMutationVariables, TContext>(
      ['deleteGame'],
      (variables?: DeleteGameMutationVariables) => fetcher<DeleteGameMutation, DeleteGameMutationVariables>(client, DeleteGameDocument, variables, headers)(),
      options
    );
export const DeleteGameMemberDocument = `
    mutation deleteGameMember($input: DeleteGameMemberInput!) {
  deleteGameMember(input: $input) {
    affected
    success
  }
}
    `;
export const useDeleteGameMemberMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteGameMemberMutation, TError, DeleteGameMemberMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteGameMemberMutation, TError, DeleteGameMemberMutationVariables, TContext>(
      ['deleteGameMember'],
      (variables?: DeleteGameMemberMutationVariables) => fetcher<DeleteGameMemberMutation, DeleteGameMemberMutationVariables>(client, DeleteGameMemberDocument, variables, headers)(),
      options
    );
export const DeleteGameTicketDocument = `
    mutation deleteGameTicket($input: DeleteGameTicketInput!) {
  deleteGameTicket(input: $input) {
    affected
    success
  }
}
    `;
export const useDeleteGameTicketMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteGameTicketMutation, TError, DeleteGameTicketMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteGameTicketMutation, TError, DeleteGameTicketMutationVariables, TContext>(
      ['deleteGameTicket'],
      (variables?: DeleteGameTicketMutationVariables) => fetcher<DeleteGameTicketMutation, DeleteGameTicketMutationVariables>(client, DeleteGameTicketDocument, variables, headers)(),
      options
    );
export const DeleteUserDocument = `
    mutation deleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    affected
    success
  }
}
    `;
export const useDeleteUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteUserMutation, TError, DeleteUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteUserMutation, TError, DeleteUserMutationVariables, TContext>(
      ['deleteUser'],
      (variables?: DeleteUserMutationVariables) => fetcher<DeleteUserMutation, DeleteUserMutationVariables>(client, DeleteUserDocument, variables, headers)(),
      options
    );
export const RemoveCurrentTicketDocument = `
    mutation removeCurrentTicket($input: RemoveCurrentTicketInput!) {
  removeCurrentTicket(input: $input) {
    accessCode
    createdAt
    currentTicket {
      createdAt
      id
      title
      updatedAt
      votedPoints
    }
    deletedAt
    description
    id
    isPrivate
    name
    showCards
    showCardsSetting
    updatedAt
    user {
      id
    }
    votingSystem
  }
}
    `;
export const useRemoveCurrentTicketMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<RemoveCurrentTicketMutation, TError, RemoveCurrentTicketMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<RemoveCurrentTicketMutation, TError, RemoveCurrentTicketMutationVariables, TContext>(
      ['removeCurrentTicket'],
      (variables?: RemoveCurrentTicketMutationVariables) => fetcher<RemoveCurrentTicketMutation, RemoveCurrentTicketMutationVariables>(client, RemoveCurrentTicketDocument, variables, headers)(),
      options
    );
export const RequestPasswordResetDocument = `
    mutation requestPasswordReset($input: RequestPasswordResetInput!) {
  requestPasswordReset(input: $input) {
    message
  }
}
    `;
export const useRequestPasswordResetMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<RequestPasswordResetMutation, TError, RequestPasswordResetMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<RequestPasswordResetMutation, TError, RequestPasswordResetMutationVariables, TContext>(
      ['requestPasswordReset'],
      (variables?: RequestPasswordResetMutationVariables) => fetcher<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(client, RequestPasswordResetDocument, variables, headers)(),
      options
    );
export const SendEmailVerificationDocument = `
    mutation sendEmailVerification($input: SendEmailVerificationInput!) {
  sendEmailVerification(input: $input) {
    message
  }
}
    `;
export const useSendEmailVerificationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SendEmailVerificationMutation, TError, SendEmailVerificationMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SendEmailVerificationMutation, TError, SendEmailVerificationMutationVariables, TContext>(
      ['sendEmailVerification'],
      (variables?: SendEmailVerificationMutationVariables) => fetcher<SendEmailVerificationMutation, SendEmailVerificationMutationVariables>(client, SendEmailVerificationDocument, variables, headers)(),
      options
    );
export const SendVoteDocument = `
    mutation sendVote($input: SendVoteInput!) {
  sendVote(input: $input) {
    createdAt
    id
    updatedAt
    user {
      addressLine1
      addressLine2
      addressLine3
      avatarUrl
      city
      companyId
      countryCode
      county
      createdAt
      deletedAt
      displayName
      email
      emailVerified
      firstName
      id
      isPrivate
      lastName
      phone
      postCode
      privacyPolicyAcceptanceDate
      receiveNewletter
      role
      status
      termsAcceptanceDate
      updatedAt
    }
    votedPoints
  }
}
    `;
export const useSendVoteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SendVoteMutation, TError, SendVoteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SendVoteMutation, TError, SendVoteMutationVariables, TContext>(
      ['sendVote'],
      (variables?: SendVoteMutationVariables) => fetcher<SendVoteMutation, SendVoteMutationVariables>(client, SendVoteDocument, variables, headers)(),
      options
    );
export const SetCurrentTicketDocument = `
    mutation setCurrentTicket($input: SetCurrentTicketInput!) {
  setCurrentTicket(input: $input) {
    accessCode
    createdAt
    currentTicket {
      createdAt
      id
      title
      updatedAt
      votedPoints
    }
    deletedAt
    description
    id
    isPrivate
    name
    showCards
    showCardsSetting
    updatedAt
    user {
      id
    }
    votingSystem
  }
}
    `;
export const useSetCurrentTicketMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SetCurrentTicketMutation, TError, SetCurrentTicketMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SetCurrentTicketMutation, TError, SetCurrentTicketMutationVariables, TContext>(
      ['setCurrentTicket'],
      (variables?: SetCurrentTicketMutationVariables) => fetcher<SetCurrentTicketMutation, SetCurrentTicketMutationVariables>(client, SetCurrentTicketDocument, variables, headers)(),
      options
    );
export const SetNewPasswordDocument = `
    mutation setNewPassword($input: SetNewPasswordInput!) {
  setNewPassword(input: $input) {
    accessToken
    user {
      addressLine1
      addressLine2
      addressLine3
      avatarUrl
      city
      companyId
      countryCode
      county
      createdAt
      deletedAt
      displayName
      email
      emailVerified
      firstName
      id
      isPrivate
      lastName
      phone
      postCode
      privacyPolicyAcceptanceDate
      receiveNewletter
      role
      status
      termsAcceptanceDate
      updatedAt
    }
  }
}
    `;
export const useSetNewPasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SetNewPasswordMutation, TError, SetNewPasswordMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SetNewPasswordMutation, TError, SetNewPasswordMutationVariables, TContext>(
      ['setNewPassword'],
      (variables?: SetNewPasswordMutationVariables) => fetcher<SetNewPasswordMutation, SetNewPasswordMutationVariables>(client, SetNewPasswordDocument, variables, headers)(),
      options
    );
export const SignInDocument = `
    mutation signIn($input: SignInInput!) {
  signIn(input: $input) {
    accessToken
    user {
      addressLine1
      addressLine2
      addressLine3
      avatarUrl
      city
      companyId
      countryCode
      county
      createdAt
      deletedAt
      displayName
      email
      emailVerified
      firstName
      id
      isPrivate
      lastName
      phone
      postCode
      privacyPolicyAcceptanceDate
      receiveNewletter
      role
      status
      termsAcceptanceDate
      updatedAt
    }
  }
}
    `;
export const useSignInMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SignInMutation, TError, SignInMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SignInMutation, TError, SignInMutationVariables, TContext>(
      ['signIn'],
      (variables?: SignInMutationVariables) => fetcher<SignInMutation, SignInMutationVariables>(client, SignInDocument, variables, headers)(),
      options
    );
export const SignUpDocument = `
    mutation signUp($input: SignUpInput!) {
  signUp(input: $input) {
    accessToken
    user {
      addressLine1
      addressLine2
      addressLine3
      avatarUrl
      city
      companyId
      countryCode
      county
      createdAt
      deletedAt
      displayName
      email
      emailVerified
      firstName
      id
      isPrivate
      lastName
      phone
      postCode
      privacyPolicyAcceptanceDate
      receiveNewletter
      role
      status
      termsAcceptanceDate
      updatedAt
    }
  }
}
    `;
export const useSignUpMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SignUpMutation, TError, SignUpMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SignUpMutation, TError, SignUpMutationVariables, TContext>(
      ['signUp'],
      (variables?: SignUpMutationVariables) => fetcher<SignUpMutation, SignUpMutationVariables>(client, SignUpDocument, variables, headers)(),
      options
    );
export const SignUpGuestDocument = `
    mutation signUpGuest($input: SignUpGuestInput!) {
  signUpGuest(input: $input) {
    accessToken
    user {
      addressLine1
      addressLine2
      addressLine3
      avatarUrl
      city
      companyId
      countryCode
      county
      createdAt
      deletedAt
      displayName
      email
      emailVerified
      firstName
      id
      isPrivate
      lastName
      phone
      postCode
      privacyPolicyAcceptanceDate
      receiveNewletter
      role
      status
      termsAcceptanceDate
      updatedAt
    }
  }
}
    `;
export const useSignUpGuestMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SignUpGuestMutation, TError, SignUpGuestMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SignUpGuestMutation, TError, SignUpGuestMutationVariables, TContext>(
      ['signUpGuest'],
      (variables?: SignUpGuestMutationVariables) => fetcher<SignUpGuestMutation, SignUpGuestMutationVariables>(client, SignUpGuestDocument, variables, headers)(),
      options
    );
export const ToggleShowCardsDocument = `
    mutation toggleShowCards($input: ToggleShowCardsInput!) {
  toggleShowCards(input: $input) {
    accessCode
    createdAt
    currentTicket {
      createdAt
      id
      title
      updatedAt
      votedPoints
    }
    deletedAt
    description
    id
    isPrivate
    name
    showCards
    showCardsSetting
    updatedAt
    user {
      id
    }
    votingSystem
  }
}
    `;
export const useToggleShowCardsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ToggleShowCardsMutation, TError, ToggleShowCardsMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ToggleShowCardsMutation, TError, ToggleShowCardsMutationVariables, TContext>(
      ['toggleShowCards'],
      (variables?: ToggleShowCardsMutationVariables) => fetcher<ToggleShowCardsMutation, ToggleShowCardsMutationVariables>(client, ToggleShowCardsDocument, variables, headers)(),
      options
    );
export const UpdateGameDocument = `
    mutation updateGame($input: UpdateGameInput!) {
  updateGame(input: $input) {
    accessCode
    createdAt
    currentTicket {
      createdAt
      id
      title
      updatedAt
      votedPoints
    }
    deletedAt
    description
    id
    isPrivate
    name
    showCards
    showCardsSetting
    updatedAt
    user {
      id
    }
    votingSystem
  }
}
    `;
export const useUpdateGameMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateGameMutation, TError, UpdateGameMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateGameMutation, TError, UpdateGameMutationVariables, TContext>(
      ['updateGame'],
      (variables?: UpdateGameMutationVariables) => fetcher<UpdateGameMutation, UpdateGameMutationVariables>(client, UpdateGameDocument, variables, headers)(),
      options
    );
export const UpdateGameTicketDocument = `
    mutation updateGameTicket($input: UpdateGameTicketInput!) {
  updateGameTicket(input: $input) {
    createdAt
    id
    title
    updatedAt
    votedPoints
  }
}
    `;
export const useUpdateGameTicketMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateGameTicketMutation, TError, UpdateGameTicketMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateGameTicketMutation, TError, UpdateGameTicketMutationVariables, TContext>(
      ['updateGameTicket'],
      (variables?: UpdateGameTicketMutationVariables) => fetcher<UpdateGameTicketMutation, UpdateGameTicketMutationVariables>(client, UpdateGameTicketDocument, variables, headers)(),
      options
    );
export const UpdateUserDocument = `
    mutation updateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    addressLine1
    addressLine2
    addressLine3
    avatarUrl
    city
    companyId
    countryCode
    county
    createdAt
    deletedAt
    displayName
    email
    emailVerified
    firstName
    id
    isPrivate
    lastName
    phone
    postCode
    privacyPolicyAcceptanceDate
    receiveNewletter
    role
    status
    termsAcceptanceDate
    updatedAt
  }
}
    `;
export const useUpdateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
      ['updateUser'],
      (variables?: UpdateUserMutationVariables) => fetcher<UpdateUserMutation, UpdateUserMutationVariables>(client, UpdateUserDocument, variables, headers)(),
      options
    );
export const VerifyEmailDocument = `
    mutation verifyEmail($input: VerifyEmailInput!) {
  verifyEmail(input: $input) {
    message
  }
}
    `;
export const useVerifyEmailMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<VerifyEmailMutation, TError, VerifyEmailMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<VerifyEmailMutation, TError, VerifyEmailMutationVariables, TContext>(
      ['verifyEmail'],
      (variables?: VerifyEmailMutationVariables) => fetcher<VerifyEmailMutation, VerifyEmailMutationVariables>(client, VerifyEmailDocument, variables, headers)(),
      options
    );
export const GetGameDocument = `
    query getGame($input: GetGameInput!) {
  getGame(input: $input) {
    accessCode
    createdAt
    currentTicket {
      createdAt
      id
      title
      updatedAt
      votedPoints
    }
    deletedAt
    description
    id
    isPrivate
    name
    showCards
    showCardsSetting
    updatedAt
    user {
      id
    }
    votingSystem
  }
}
    `;
export const useGetGameQuery = <
      TData = GetGameQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetGameQueryVariables,
      options?: UseQueryOptions<GetGameQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetGameQuery, TError, TData>(
      ['getGame', variables],
      fetcher<GetGameQuery, GetGameQueryVariables>(client, GetGameDocument, variables, headers),
      options
    );
export const GetGameMemberDocument = `
    query getGameMember($input: GetGameMemberInput!) {
  getGameMember(input: $input) {
    createdAt
    id
    updatedAt
    user {
      addressLine1
      addressLine2
      addressLine3
      avatarUrl
      city
      companyId
      countryCode
      county
      createdAt
      deletedAt
      displayName
      email
      emailVerified
      firstName
      id
      isPrivate
      lastName
      phone
      postCode
      privacyPolicyAcceptanceDate
      receiveNewletter
      role
      status
      termsAcceptanceDate
      updatedAt
    }
    votedPoints
  }
}
    `;
export const useGetGameMemberQuery = <
      TData = GetGameMemberQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetGameMemberQueryVariables,
      options?: UseQueryOptions<GetGameMemberQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetGameMemberQuery, TError, TData>(
      ['getGameMember', variables],
      fetcher<GetGameMemberQuery, GetGameMemberQueryVariables>(client, GetGameMemberDocument, variables, headers),
      options
    );
export const GetGameMembersDocument = `
    query getGameMembers($input: GetGameMembersInput!) {
  getGameMembers(input: $input) {
    createdAt
    id
    updatedAt
    user {
      addressLine1
      addressLine2
      addressLine3
      avatarUrl
      city
      companyId
      countryCode
      county
      createdAt
      deletedAt
      displayName
      email
      emailVerified
      firstName
      id
      isPrivate
      lastName
      phone
      postCode
      privacyPolicyAcceptanceDate
      receiveNewletter
      role
      status
      termsAcceptanceDate
      updatedAt
    }
    votedPoints
  }
}
    `;
export const useGetGameMembersQuery = <
      TData = GetGameMembersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetGameMembersQueryVariables,
      options?: UseQueryOptions<GetGameMembersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetGameMembersQuery, TError, TData>(
      ['getGameMembers', variables],
      fetcher<GetGameMembersQuery, GetGameMembersQueryVariables>(client, GetGameMembersDocument, variables, headers),
      options
    );
export const GetGameTicketDocument = `
    query getGameTicket($input: GetGameTicketInput!) {
  getGameTicket(input: $input) {
    createdAt
    id
    title
    updatedAt
    votedPoints
  }
}
    `;
export const useGetGameTicketQuery = <
      TData = GetGameTicketQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetGameTicketQueryVariables,
      options?: UseQueryOptions<GetGameTicketQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetGameTicketQuery, TError, TData>(
      ['getGameTicket', variables],
      fetcher<GetGameTicketQuery, GetGameTicketQueryVariables>(client, GetGameTicketDocument, variables, headers),
      options
    );
export const GetGameTicketsDocument = `
    query getGameTickets($input: GetGameTicketsInput!) {
  getGameTickets(input: $input) {
    createdAt
    id
    title
    updatedAt
    votedPoints
  }
}
    `;
export const useGetGameTicketsQuery = <
      TData = GetGameTicketsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetGameTicketsQueryVariables,
      options?: UseQueryOptions<GetGameTicketsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetGameTicketsQuery, TError, TData>(
      ['getGameTickets', variables],
      fetcher<GetGameTicketsQuery, GetGameTicketsQueryVariables>(client, GetGameTicketsDocument, variables, headers),
      options
    );
export const GetUserDocument = `
    query getUser($input: GetUserInput!) {
  getUser(input: $input) {
    addressLine1
    addressLine2
    addressLine3
    avatarUrl
    city
    companyId
    countryCode
    county
    createdAt
    deletedAt
    displayName
    email
    emailVerified
    firstName
    id
    isPrivate
    lastName
    phone
    postCode
    privacyPolicyAcceptanceDate
    receiveNewletter
    role
    status
    termsAcceptanceDate
    updatedAt
  }
}
    `;
export const useGetUserQuery = <
      TData = GetUserQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetUserQueryVariables,
      options?: UseQueryOptions<GetUserQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetUserQuery, TError, TData>(
      ['getUser', variables],
      fetcher<GetUserQuery, GetUserQueryVariables>(client, GetUserDocument, variables, headers),
      options
    );