import { useDeleteTicketConfirmDialog } from '@app/hooks/useDeleteTicketConfirmDialog';
import { AddTicketsDialog } from '@app/page-content/game/tickets/AddTicketsDialog';
import { DeleteTicketConfirmDialog } from '@app/page-content/game/tickets/DeleteTicketConfirmDialog';

import { DialogId, useDialog } from 'src/hooks/useDialog';
import { useJoinGameDialog } from 'src/hooks/useJoinGameDialog';
import { useLoginDialog } from 'src/hooks/useLoginDialog';
import { useUserSetupDialog } from 'src/hooks/useUserSetupDialog';
import { JoinGameDialog } from 'src/page-content/join-game/JoinGameDialog';
import { UserSetupDialog } from 'src/page-content/join-game/UserSetupDialog';
import { LoginDialog } from 'src/page-content/login/LoginDialog';

export const ModalsContainer = () => {
  const { loginDialog } = useLoginDialog();
  const { joinGameDialog } = useJoinGameDialog();
  const { userSetupDialog } = useUserSetupDialog();
  const addTicketsDialog = useDialog(DialogId.AddTickets);
  const { deleteTicketConfirmDialog } = useDeleteTicketConfirmDialog();

  return (
    <>
      {loginDialog.open && <LoginDialog />}
      {joinGameDialog.open && <JoinGameDialog />}
      {userSetupDialog.open && <UserSetupDialog />}
      {addTicketsDialog.open && <AddTicketsDialog />}
      {deleteTicketConfirmDialog.open && <DeleteTicketConfirmDialog />}
    </>
  );
};
