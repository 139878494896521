import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import { getBearerAccessToken, gqlClient } from 'src/api/gqlClient';
import { useGetUserQuery, User } from 'src/gql-generated';
import { atoms } from 'src/state/atoms';

import { CookieKey } from '../CookieKey';
import { cookiesService } from '../cookiesService';

export const useAuth = () => {
  const [token, setToken] = useAtom(atoms.accessToken);
  const id = cookiesService.get(CookieKey.UserId);
  const { remove, data } = useGetUserQuery(
    gqlClient,
    { input: { id } },
    { enabled: !!id }
  );
  const queryClient = useQueryClient();
  const user = data?.getUser;

  const logout = () => {
    cookiesService.set(CookieKey.Token, '');
    cookiesService.set(CookieKey.UserId, '');
    setToken('');
    gqlClient.setHeader('authorization', '');
    remove();
  };

  const login = ({
    accessToken,
    user,
  }: {
    accessToken: string;
    user: User;
  }) => {
    cookiesService.set(CookieKey.Token, accessToken);
    cookiesService.set(CookieKey.UserId, user.id);
    setToken(accessToken);
    gqlClient.setHeader('authorization', getBearerAccessToken());
    queryClient.invalidateQueries();
  };

  return { token, isLoggedIn: !!token, logout, login, user };
};
